import {TextInput, Edit, SimpleForm, BooleanInput} from "react-admin";

export default function UserEdit(){
  return <Edit actions={null}>
    <SimpleForm>
      <TextInput source="firstname" label="Prénom" />
      <TextInput source="lastname" label="Nom" />
      <TextInput source="email" label="Email" />
      <BooleanInput source="enabled" label="Activé" />
    </SimpleForm>
  </Edit>
}