import {defaultTheme} from "react-admin";
import loginBackgroundImage from './images/background-login-image.webp';
import FreightBigBook from './fonts/Freight-Big-Book.otf';

const customTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      contrastText: '#ffffff',
      main: '#C69F75',
      light: '#F3EEE5',
      dark: '#b68F65',
    },
    secondary: {
      ...defaultTheme.palette.secondary,
      main: '#0D263A',
    },
    danger: {
      main: '#d32f2f',
    },
    mode: 'light',
  },
  typography: {
    fontFamily: 'FreightBigBook, Arial',
  },
  components: {
    ...defaultTheme.components,
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'FreightBigBook';
          font-style: normal;
          src: url(${FreightBigBook}) format('otf');
        }
      `,
    },
    RaLayout: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          "& #main-content": {
            backgroundColor: "white",
          },
          "& .RaList-content": {
            transition: "revert",
            borderRadius: "revert",
            boxShadow: "revert",
            background: "transparent",
          },
          "& .RaLayout-content": {
            paddingLeft: 0,
            paddingRight: 0,
          }
        }
      },
    },
    RaLogin: {
      styleOverrides: {
        root: {
          backgroundImage: `linear-gradient(to bottom, rgba(13, 38, 58, 0.70), rgba(13, 38, 58, 0.70)), url(${loginBackgroundImage})`,
        }
      }
    },
    RaButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      }
    },
    RaAppBar: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          "& > *": {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }
        },
      }
    },
    RaImageField: {
      styleOverrides: {
        root: {
          textAlign: "center",
          "& > .RaImageField-image": {
            margin: "0 auto",
            width: "768px",
            height: "auto",
            maxWidth: "100%",
          }
        },
      }
    },
    RaSidebarToggleButton: {
      styleOverrides: {
        root: {
          display: "none",
        },
      }
    },
    RaToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: '12rem',
          "& button": {
            color: 'primary',
            variant: "contained",
            width: '100%'
          }
        }
      }
    },
    RaResettableTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        }
      }
    }
  },
};

export default customTheme;