import {Card, CardContent, Typography, Grid, Link} from '@mui/material';
import {
  PasswordInput,
  Form,
  useNotify,
  useRefresh,
  useGetIdentity,
  useGetOne,
  TextField,
  FunctionField,
  RecordContextProvider, SimpleShowLayout, SaveButton, Button,
} from "react-admin";
import {ENTRYPOINT} from "../../App";
import {useState} from "react";
import {Link as RouterLink} from "react-router-dom";

function UserProfile({onPasswordChange}){
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  return <Card sx={{margin: '1em', marginTop: '2em'}}>
      <CardContent>
        <Link component={RouterLink} to="/">Retour à l'accueil</Link>
        <SimpleShowLayout>
          <Typography variant="h4">Mon profil</Typography>
          <FunctionField render={user => user.firstname + ' ' + user.lastname} label="Nom" />
          <TextField source="email" label="Email" />
        </SimpleShowLayout>
        {!showPasswordForm &&
          <Button
            onClick={() => setShowPasswordForm(true)}
            label="Changer mon mot de passe"
          />
        }
        {showPasswordForm &&
          <Form onSubmit={async data => {
            const result = await onPasswordChange(data);
            if (result){
              setShowPasswordForm(false);
            }
          }}>
            <PasswordInput
              name="plainPassword"
              label="Nouveau mot de passe"
              autoFocus
              fullWidth
            />
            <Grid container>
              <Grid item xs={6}>
                <SaveButton label="Envoyer" />
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Button
                  color="danger"
                  onClick={() => setShowPasswordForm(false)}
                  label="Annuler"
                />
              </Grid>
            </Grid>
          </Form>
        }
      </CardContent>
    </Card>
}

export default function Profile(){
  const identity = useGetIdentity();
  const refresh = useRefresh();
  const notify = useNotify();
  const {data, isLoading} =  useGetOne('users', {id: 'api/users/' + identity.data?.id}, {enabled: !identity.isLoading});
  if (identity.isLoading || isLoading){
    return null;
  }
  return <RecordContextProvider value={data}>
    <UserProfile
      onPasswordChange={async data => {
        const result = await fetch(ENTRYPOINT + '/change-password/' + identity.data.id,{
          method: 'PUT',
          credentials: 'include',
          body: JSON.stringify({
            plainPassword: data.plainPassword
          }),
          headers: new Headers({'Content-Type': 'application/json'})
        });
        if (result.ok){
          notify('Mot de passe modifié.', {type: "success"});
          refresh();
          return true;
        } else {
          const json = await result.json();
          const passwordErrorMessage = 'Le mot de passe doit contenir au moins 8 caractères dont un chiffre et un caractère non alphanumérique.';
          const unknownErrorMessage = 'La mise à jour du mot de passe a échoué.';
          const message = json.violations
            .filter(violation => violation.propertyPath === 'plainPassword')
            .length > 0 ? passwordErrorMessage : unknownErrorMessage
          ;
          notify(message, {type: "error"});
          return false;
        }
      }}
    />
  </RecordContextProvider>
}