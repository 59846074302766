import {usePermissions} from "react-admin";
import CentaurusLayout from "../presentational/CentaurusLayout";

export default function Layout(props){
  const {permissions} = usePermissions();
  return <CentaurusLayout
    {...props}
    isAdmin={permissions === 'admin'}
    sidebar={permissions === 'admin' ? undefined : () => null}
    isDisconnected={permissions !== 'admin' && permissions !== 'user'}
  />
}