import {TextField, BooleanField, usePermissions, List, Datagrid, EditButton} from "react-admin";

export default function UserList(props){
  const {permissions} = usePermissions();
  return <List
      {...props}
      emptyWhileLoading
      hasShow={false}
      hasCreate={permissions === 'admin'}
      hasEdit={permissions === 'admin'}
      exporter={false}
      pagination={false}
      sx={{paddingRight: '1rem'}}
    >
    <Datagrid bulkActionButtons={false}>
      <TextField source="email" />
      <TextField source="lastname" label="Nom" />
      <TextField source="firstname" label="Prénom" />
      <TextField source="createdAt" label="Créé le" />
      <TextField source="displayRoles" label="Rôle" />
      <BooleanField source="enabled" label="Activé" />
      <EditButton />
    </Datagrid>
    </List>
}