import * as React from 'react';
import {AppBar, UserMenu, Logout, MenuItemLink} from 'react-admin';
import Logo from './Logo.svg';
import styled from "@emotion/styled";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import { AppBar as DisconnectedAppBar } from '@mui/material';
import { Link } from 'react-router-dom';

const AppBarInsider = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
const CentaurusLogo = styled.img`
  max-width: 100%;
  width: 12rem;
  height: auto;
  margin: auto;
`;

const CentaurusAppBar = ({isDisconnected, ...otherProps}) => (
  <>
    {
      isDisconnected ? (
        <DisconnectedAppBar
          sx={{
          height: '4rem',
          justifyContent: 'center',
          width: '100%',
          backgroundColor: '#0D263A'
          }}
          >
          <AppBarInsider>
            <Link to="/">
              <CentaurusLogo src={Logo} alt="CENTAURUS - Hospitality odyssey" />
            </Link>
          </AppBarInsider>
        </DisconnectedAppBar>
      ) : (
        <AppBar
            sx={{
              height: '4rem',
              justifyContent: 'center',
              width: '100%',
            }}
          {...otherProps}
            userMenu={<UserMenu icon={<PeopleIcon />}>
              <MenuItemLink
                to="/profil"
                primaryText="Mon profil"
                leftIcon={<PersonIcon />}
              />
              <Logout {...otherProps} icon={<ExitToAppIcon />} />
            </UserMenu>}
          >
            <AppBarInsider>
              <Link to="/">
                <CentaurusLogo src={Logo} alt="CENTAURUS - Hospitality odyssey" />
              </Link>
            </AppBarInsider>
        </AppBar>
      )
    }
  </>
);

export default CentaurusAppBar;