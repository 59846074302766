import * as React from 'react';
import { Layout } from 'react-admin';
import CentaurusAppBar from './CentaurusAppBar';

const CentaurusLayout = ({isAdmin, ...otherProps}) => {
    return (
        <Layout sx={{ "& .MuiToolbar-root.MuiToolbar-gutters": { backgroundColor: isAdmin ? 'white' : '#f3eee5' } }} {...otherProps} appBar={() => <CentaurusAppBar {...otherProps} />} />
    )
};

export default CentaurusLayout;
