import {
  Button,
  Confirm,
  Create,
  Datagrid,
  EditButton,
  FileField,
  FileInput,
  ReferenceManyField,
  FunctionField,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useCreateContext,
  useDelete,
  useGetRecordId,
  useNotify,
  usePermissions,
  useRefresh,
  useShowContext, ListButton,
  required
} from "react-admin"
import {useFormContext} from "react-hook-form";
import React, {useEffect, useMemo, useState} from "react";
import Delete from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import CentaurusProjectImage from "../presentational/CentaurusProjectImage";
import {Typography} from "@mui/material";
import MultilineText from "./MultilineText";
import {useTranslate} from "ra-core";
import LogoPDF from '../../images/pdf-icon.svg';

function CreateDocument(props){
  const {record} = useShowContext();
  const {save} = useCreateContext();
  const [submissions, setSubmissions] = useState(0);
  return <SimpleForm
    onSubmit={data => {
      data.project = record.id;
      save(data, {
        onSuccess: () => {
          props.onCreate();
          setSubmissions(submissions + 1);
        }
      });
    }}
  >
    <CreateDocumentForm submissions={submissions} />
  </SimpleForm>
}

function CreateDocumentForm(props){
  const {reset} = useFormContext();
  const translate = useTranslate();
  const notify = useNotify();
  const [submissions, setSubmissions] = useState(props.submissions);
  useEffect(() => {
    if (props.submissions !== submissions){
      reset();
      setSubmissions(props.submissions);
    }
  }, [props.submissions, submissions]);
  return <>
    <Box as="h3" sx={{ fontFamily: 'FreightBigBook', color: '#C69F75'}}>Ajouter un document</Box>
    <TextInput name="title" source="title" label="Titre" required />
    <FileInput
      source="file"
      label={false}
      accept=".pdf"
      maxSize={10000000}
      validate={required()}
      placeholder={<>
        <p>{translate('ra.input.file.upload_single')}</p>
        <p>Type de fichier accepté : PDF</p>
        <p>Taille maximale : 10Mo</p>
      </>}
      options={{
        onDropRejected: rejections => {
          rejections.map(rejection => {
            rejection.errors.map(error => {
              if (error.code === 'file-too-large'){
                notify('centaurus.file_too_large', {type: 'error'});
                return;
              }
              notify(error.message, {type: 'error'});
            });
          });
        }
      }}
    >
      <FileField source="src" title="title" />
    </FileInput>
  </>
}
function DeleteButton(){
  const recordId = useGetRecordId();
  const refresh = useRefresh();
  const notify = useNotify();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteOne] = useDelete(
    'documents',
    {id: recordId},
    {
      onSuccess: (data) => {
        refresh();
        notify('Document supprimé');
        setConfirmDeleteOpen(false);
    },
      onError: (error) => {
        notify(`La suppression a échoué. ${error.message}`, {type: 'error'});
        setConfirmDeleteOpen(false);
      }
    }
  );
  return <>
    {!confirmDeleteOpen &&
      <Button
        onClick={() => setConfirmDeleteOpen(true)}
        label="Supprimer"
        color="danger"
      >
      <Delete/>
    </Button>
    }
    {confirmDeleteOpen &&
    <Confirm
      isOpen={confirmDeleteOpen}
      title="Suppression d'un document"
      content="Ce document va être supprimé."
      onConfirm={deleteOne}
      onClose={() => setConfirmDeleteOpen(false)}
    />
    }
  </>
}

function DocumentRow({isAdmin}){
  return isAdmin ? (
    <FileField source="fileUrl" title="title" target="_blank" label={false} />
  ) : (
    <Box as="li" sx={{ display: "flex", alignItems: "center", gap: ".5rem"}}>
      <Box as="img" src={LogoPDF} alt="" sx={{ height: "1.6rem" }}/>
      <FileField source="fileUrl" title="title" target="_blank" label={false} />
    </Box>
  )
}

function ShowProject(props){
  const {record} = useShowContext();
  const {permissions} = usePermissions();
  const isAdmin = useMemo(() => {
    return permissions === 'admin'
  }, [permissions]);
  const refresh = useRefresh();
  return <SimpleShowLayout sx={{ padding: 'revert'}}>
    <Box sx={{ display: 'flex', gap: "1rem", flexDirection: { xs: 'column', md: 'row'}}}>
      <Box sx={{ flexBasis: "50%", position: "relative", height: "100vh"}}>
    {record.imageUrl !== 'undefined' &&
      <CentaurusProjectImage source="imageUrl"/>
    }
      </Box>
      <Box sx={{ flexBasis: "50%", paddingTop: { xs: 0, md: "1rem"}}}>
        {!isAdmin &&
          <ListButton resource="projects" label="Liste des projets" sx={{ paddingLeft: "1rem"}} />
        }
        <Box sx={{ display: 'flex', flexDirection: 'column'}}>
        <TextField
          source="name"
          label=""
          variant="h2"
          sx={{
            fontFamily: 'FreightBigBook',
            color: '#C69F75',
            textAlign: 'center',
            marginBottom: "2rem"
          }}
        />
    <FunctionField
      render={record => <MultilineText text={record.description} />}
      label="Description"
      variant="body1"
      sx={{
        marginBottom: isAdmin ? ".5rem" : "revert",
        fontFamily: 'FreightBigBook',
        padding: "1rem"
      }}
    />
        </Box>
    <ReferenceManyField sx={{ padding: "1rem"}} label="Documents" reference={`projects/${record.originId}/documents`} source="documents" target="project">
      <Box as="h3" sx={{ fontFamily: 'FreightBigBook', color: '#C69F75', paddingLeft: "1rem"}}>Liste des documents</Box>
      <Datagrid
        isRowSelectable={() => isAdmin}
        bulkActionButtons={isAdmin}
        empty={<Typography sx={{ paddingLeft: "1rem"}}>Aucun document pour ce projet.</Typography>}
        sx={{ paddingLeft: "1rem", "& thead": { display: !isAdmin && "none" }}}
      >
        <DocumentRow isAdmin={isAdmin} />
        {isAdmin && <EditButton resource="documents" />}
        {isAdmin && <DeleteButton />}
      </Datagrid>
    </ReferenceManyField>
      {isAdmin &&
        <Create
          title=""
          resource="documents"
          redirect={false}
          sx={{'& *': { boxShadow: 'revert' }}}
        >
          <CreateDocument onCreate={refresh} />
        </Create>
      }
      </Box>
    </Box>
  </SimpleShowLayout>
}
export default function ProjectShow(props){
  const {permissions} = usePermissions();
  return <Show
    {...props}
    emptyWhileLoading
    actions={permissions === 'admin' ? undefined : null}
    sx={{ boxShadow: 'revert', "& .RaShow-noActions": { marginTop: { xs: 0, md: ".8em"}}}}
  >
    <ShowProject />
  </Show>
}