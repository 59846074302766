import {Card, Link, Typography} from '@mui/material';
import {DeleteButton, SaveButton, SimpleForm, TextInput, Toolbar} from "react-admin";
import {Link as RouterLink} from "react-router-dom";

export default function ResetPasswordQuery ({requestSentForEmail, onSubmit}) {
  return <Card>
      <Link component={RouterLink} to="/login" sx={{ padding: "1rem"}}>Retour login</Link>
      {requestSentForEmail &&
        <Typography padding={2}>
          Un email a été envoyé à l'adresse <strong>{requestSentForEmail}</strong> avec un lien
          permettant de réinitialiser votre mot de passe.
        </Typography>
      }
      {!requestSentForEmail &&
        <SimpleForm
          onSubmit={data => onSubmit(data)}
          toolbar={<Toolbar><SaveButton label="Envoyer" /></Toolbar>}
        >
          <TextInput name="email" label="Saisissez votre email" fullWidth autoFocus />
        </SimpleForm>
      }
  </Card>
}