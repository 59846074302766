import {API_SERVER_URL} from "../App";

export const authProvider = {
  // send username and password to the auth server and get back credentials
  login: ({username, password}) => {
    return fetch(API_SERVER_URL + '/api/login', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({username, password}),
      headers: new Headers({'Content-Type': 'application/json'})
    }).then(res => {
      if (res.status === 401){
        return Promise.reject();
      }
      return res.json().then(json => {
        localStorage.setItem('username', username)
        localStorage.setItem('userId', json.logged_user.id)
        localStorage.setItem('userRoles', json.logged_user.roles)
        return Promise.resolve();
      });
    });
  },
  // when the dataProvider returns an error, check if this is an authentication error
  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('username');
      localStorage.removeItem('userRoles');
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  // when the user navigates, make sure that their credentials are still valid
  checkAuth: params => {
    return localStorage.getItem('username') ? Promise.resolve() : Promise.reject({message: false});
  },
  // remove local credentials and notify the auth server that the user logged out
  logout: () => {
    localStorage.removeItem('username');
    localStorage.removeItem('userRoles');
    fetch(API_SERVER_URL + '/api/logout');
    return Promise.resolve();
  },
  // get the user's profile
  getIdentity: () => {
    const username = localStorage.getItem('username');
    const userId = localStorage.getItem('userId');
    return username ? Promise.resolve({id: userId, fullName: username}) : Promise.reject();
  },
  // get the user permissions (optional)
  getPermissions: () => {
    const roles = localStorage.getItem('userRoles')
    const username = localStorage.getItem('username')
    if (!roles || !username){
      return Promise.reject();
    }

    return Promise.resolve(roles.includes('ROLE_SUPER_ADMIN') ? 'admin' : 'user');
  },
};