import {Card, Link} from '@mui/material';
import {SaveButton, DeleteButton, PasswordInput, SimpleForm, Toolbar, useNotify, useRedirect} from "react-admin";
import {Link as RouterLink, useParams} from "react-router-dom";
import {ENTRYPOINT} from "../../App";

function CustomToolbar(){
  return <Toolbar>
    <SaveButton label="Envoyer" />
    <DeleteButton />
  </Toolbar>
}

export default function ResetPassword(props){
  const {token} = useParams();
  const redirect = useRedirect();
  const notify = useNotify();
  return <Card>
      <Link component={RouterLink} to="/login" sx={{ padding: "1rem"}}>Retour login</Link>
      <SimpleForm
        onSubmit={async data => {
          const result = await fetch(ENTRYPOINT + '/reset-password/' + token,{
            method: 'PUT',
            body: JSON.stringify({
              plainPassword: data.plainPassword
            }),
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: 'include',
          });
          if (result.ok){
            notify('Mot de passe modifié.', {type: "success"});
            redirect('/');
            return;
          }
          if (result.status === 404) {
            notify('Le lien que vous avez suivi a expiré. Veuillez renvoyer une demande.', {type: "error", autoHideDuration: 10000});
            redirect('/reset-password');
            return;
          }

          const json = await result.json();
          const passwordErrorMessage = 'Le mot de passe doit contenir au moins 8 caractères dont un chiffre et un caractère non alphanumérique.';
          const unknownErrorMessage = 'La mise à jour du mot de passe a échoué.';
          const message = json.violations
            .filter(violation => violation.propertyPath === 'plainPassword')
            .length > 0 ? passwordErrorMessage : unknownErrorMessage
          ;
          notify(message, {type: "error", autoHideDuration: 10000});
        }}
        toolbar={<CustomToolbar />}
      >
        <PasswordInput
          name="plainPassword"
          label="Nouveau mot de passe"
          autoFocus
          fullWidth
        />
      </SimpleForm>
  </Card>
}