import {useState} from "react";
import {ENTRYPOINT} from "../../App";
import PresentationalResetPasswordQuery from '../presentational/ResetPasswordQuery'
import {useNotify} from "react-admin";

export default function ResetPasswordQuery(){
  const [requestSentForEmail, setRequestSentForEmail] = useState(null);
  const notify = useNotify();
  return <PresentationalResetPasswordQuery
    requestSentForEmail={requestSentForEmail}
    onSubmit={async data => {
      const result = await fetch(ENTRYPOINT + '/reset-password',{
        method: 'POST',
        body: JSON.stringify({
          email: data.email
        }),
        headers: new Headers({'Content-Type': 'application/json'})
      });
      if (result.ok){
        setRequestSentForEmail(data.email);
        return;
      }
      if (result.status === 400){
        const data = await result.json();
        notify(data['hydra:description'], {type: "error"});
      }
    }}
  />
}