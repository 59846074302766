import {useMemo, useState} from "react";
import {Grid, Typography, Button} from "@mui/material";
import customTheme from "../../customTheme";

export default function Captcha({onSuccess}){
  const [count, setCount] = useState(0);
  const numbers = [2, 1, 3];
  const finalCount = useMemo(() => {
    return Math.max(...numbers);
  }, [numbers]);
  return <>
    <Typography padding={1} maxWidth={'18rem'} textAlign="justify">Veuillez compter jusqu'à trois pour prouver que vous n'êtes pas un robot.</Typography>
    <Typography padding={1} textAlign="center">Vous en êtes à {count}</Typography>
    <Grid container spacing={2} padding={2}>
      {numbers.map(number => {
        return <Grid key={number} item xs={4}>
          <Button
            sx={
            { width: '100%', border: '1px solid', fontWeight: 'bold', paddingTop: '0', paddingBottom: '0',
            '&:hover': {
              color: '#ffffff',
              backgroundColor: customTheme.palette.primary.dark
            }
          }}
            onClick={() => {
              if (count + 1 !== number){
                setCount(0);
                return;
              }
              setCount(number);
              if (number === finalCount){
                onSuccess();
              }
            }}
          >{number}</Button>
        </Grid>
      })}
    </Grid>
  </>
}