import {Edit, FileField, FileInput, SimpleForm, TextInput} from "react-admin";
import {useTranslate} from "ra-core";

export default function DocumentEdit(){
  const translate = useTranslate();
  return (
    <Edit redirect={(resource,id, data) => 'projects/' + encodeURIComponent(data.project['@id'])+'/show'}>
      <SimpleForm sanitizeEmptyValues={true}>
        <TextInput source="title" label="Titre" />
        <FileField source="fileUrl" />
        <FileInput
          source="file"
          label="Modifier le fichier"
          accept=".pdf"
          maxSize={500000000}
          placeholder={<>
            <p>{translate('ra.input.file.upload_single')}</p>
            <p>Type de fichier accepté : PDF</p>
            <p>Taille maximale : 10Mo</p>
          </>}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  )
}