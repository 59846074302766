import {Create, SimpleForm, TextInput, useNotify} from "react-admin";
import {ENTRYPOINT} from "../../App";
import {useState} from "react";
import {Link, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import Captcha from "./Captcha";

export default function Register(){
  const [registeredEmail, setRegisteredEmail] = useState(null);
  const [captchaOK, setCaptchaOK] = useState(false);
  const notify = useNotify();
  return <>
    <Link component={RouterLink} to="/login" sx={{ padding: "1rem"}}>Retour login</Link>
    {registeredEmail &&
      <Typography padding={2}>
        Votre demande a bien été prise en compte, vos identifiants vous seront transmis dans
        les plus brefs délais à l'adresse suivante : {registeredEmail}.
      </Typography>
    }
    {!registeredEmail && <SimpleForm
      onSubmit={async data => {
        if (!captchaOK){
          notify('Vous devez prouver que vous n\'êtes pas un robot', {type: "error"});
          return;
        }
        const result = await fetch(ENTRYPOINT + '/register', {
          method: 'POST',
          body: JSON.stringify(data),
          headers: new Headers({'Content-Type': 'application/json'})
        });
        if (result.ok) {
          setRegisteredEmail(data.email);
          return;
        }
        if (result.status === 400) {
          const data = await result.json();
          notify(data['hydra:description'], {type: "error"});
        }
      }}
      toolbar={captchaOK ? undefined : <Captcha onSuccess={() => setCaptchaOK(true)} />}
    >
      <TextInput source="firstname" label="Prénom"/>
      <TextInput source="lastname" label="Nom"/>
      <TextInput source="email" label="Email"/>
    </SimpleForm>}
    </>
}