import styled from "@emotion/styled";

const ProjectsList = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

export default function ProjectList(props) {
  return <ProjectsList>{props.children}</ProjectsList>
}
