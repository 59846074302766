import {Navigate, Route} from "react-router-dom";
import {
  fetchHydra as baseFetchHydra,
  HydraAdmin,
  hydraDataProvider as baseHydraDataProvider,
  useIntrospection
} from "@api-platform/admin";
import {parseHydraDocumentation} from "@api-platform/api-doc-parser";
import {authProvider} from "./security/authProvider";
import ProjectList from "./components/app/ProjectList";
import ProjectShow from "./components/app/ProjectShow";
import {Resource, Login, LoginForm, CustomRoutes} from "react-admin";
import customTheme from './customTheme'
import ProjectCreate from "./components/app/ProjectCreate";
import ProjectEdit from "./components/app/ProjectEdit";
import DocumentEdit from "./components/app/DocumentEdit";
import UserEdit from "./components/app/UserEdit";
import UserList from "./components/app/UserList";
import UserCreate from "./components/app/UserCreate";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import fr from 'ra-language-french';
import Layout from "./components/app/Layout";
import ResetPassword from "./components/app/ResetPassword";
import ResetPasswordQuery from "./components/app/ResetPasswordQuery";
import Register from "./components/app/Register";
import { Link as RouterLink } from 'react-router-dom';
import {Link, Typography} from '@mui/material';
import Profile from "./components/app/Profile";

export const API_SERVER_URL = '';
export const ENTRYPOINT = API_SERVER_URL + '/api';

const frenchMessages = {
  ...fr,
  centaurus: {
    file_too_large: 'Le fichier est trop volumineux'
  }

}

const i18nProvider = polyglotI18nProvider(() => frenchMessages, 'fr');


const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    credentials: 'include',
  });

const RedirectToLogin = () => {
  const introspect = useIntrospection();

  if (localStorage.getItem("username")) {
    introspect();
    return <></>;
  }
  return <Navigate to="/login" />;
};
const apiDocumentationParser = async () => {
  try {
    return await parseHydraDocumentation(ENTRYPOINT);
  } catch (result) {
    const { api, response, status } = result;
    if (status !== 401 || !response) {
      throw result;
    }

    // Prevent infinite loop if the token is expired
    localStorage.removeItem("jwt");

    return {
      api,
      response,
      status,
      customRoutes: [
        <Route key="/" path="/" component={RedirectToLogin} />
      ],
    };
  }
};
const baseDataProvider = baseHydraDataProvider({
  entrypoint: ENTRYPOINT,
  httpClient: fetchHydra,
  apiDocumentationParser,
});

const dataProvider = {
  ...baseDataProvider,
  getOne: (resource, params) => {
    if (resource === 'projects'){
      return baseDataProvider.getOne(resource, params).then(({data}) => {
        return {
          data: {
            ...data,
            imageUrl: API_SERVER_URL + data.imageUrl
          }
        };
      });

    }
    return baseDataProvider.getOne(resource, params);
  },
  getList: (resource, params) => {
    if (resource === 'projects'){
      return baseDataProvider.getList(resource, params).then(({data, total}) => {
        return {
          data: data.map(project => ({
            ...project,
            imageUrl: project.imageUrl ? API_SERVER_URL + project.imageUrl : null
          })),
          total
        };
      });
    }

    return baseDataProvider.getList(resource, params);
  },
  getManyReference: (resource, params) => {
    if (resource.includes('documents')){
      return baseDataProvider.getList(resource, params).then(({data, total}) => {
        return {
          data: data.map(document => ({
            ...document,
            fileUrl: document.fileUrl ? API_SERVER_URL + document.fileUrl : null
          })),
          total
        };
      });
    }

    return baseDataProvider.getList(resource, params);
  },
  update: (resource, params) => {
    if (resource === 'projects' || resource === 'documents'){

      let newParams = {
        ...params,
        data: {
          ...params.data,
          '_method': 'PUT',
          extraInformation: {
            hasFileField: true
          }
        }
      };
      return baseDataProvider.update(resource, newParams);
    }
    return baseDataProvider.update(resource, params);
  }
};

export default () => {
  return <HydraAdmin
    entrypoint={ENTRYPOINT}
    dataProvider={dataProvider}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
    layout={Layout}
    loginPage={() => <Layout>
      <Login>
        <LoginForm />
        <Link component={RouterLink} to="/reset-password">
          <Typography marginLeft={2} marginBottom={1}>
            Mot de passe oublié ?
          </Typography>
        </Link>
        <Link component={RouterLink} to="/register">
          <Typography marginLeft={2} marginBottom={2}>
            S'inscrire
          </Typography>
        </Link>
      </Login>
    </Layout>
    }
    theme={customTheme}
  >
    {permissions => (
      <>
        {permissions === 'admin' &&
          <>
            <Resource
              name="users"
              options={{label: "Utilisateurs"}}
              list={UserList}
              show={null}
              create={UserCreate}
              edit={UserEdit}
            />
            <Resource
              name="documents"
              options={{label: "Documents"}}
              edit={DocumentEdit}
            />
          </>
        }
        {['admin', 'user'].includes(permissions) &&
          <Resource
            name="projects"
            options={{label: "Projets"}}
            list={ProjectList}
            show={ProjectShow}
            create={permissions === 'admin' && ProjectCreate}
            edit={permissions === 'admin' && ProjectEdit}
          />
        }
        <CustomRoutes>
          {!permissions && <Route path="/" element={<RedirectToLogin/>}/>}
          <Route path="/profil" element={<Profile />} />
          <Route path="/reset-password/:token" element={<Login><ResetPassword /></Login>} />
          <Route path="/reset-password" element={<Login><ResetPasswordQuery /></Login>} />
          <Route path="/register" element={<Login><Register /></Login>} />
        </CustomRoutes>
      </>
    )}
  </HydraAdmin>
};