import {Create, ImageField, ImageInput, SimpleForm, TextInput, useNotify} from "react-admin";
import {useTranslate} from "ra-core";
import React from "react";

export default function ProjectCreate(){
  const translate = useTranslate();
  const notify = useNotify();
  return <Create>
    <SimpleForm>
      <TextInput source="name" label="Titre" required />
      <TextInput multiline minRows={5} source="description" label="Description" required />
      <ImageInput
        source="image"
        label="Image d'illustration"
        accept=".jpg,.png,.gif"
        maxSize={5000000}
        placeholder={<>
          <p>{translate('ra.input.image.upload_single')}</p>
          <p>Types de fichiers acceptés : JPG, PNG, GIF</p>
          <p>Taille maximale : 5Mo</p>
        </>}
        options={{
          onDropRejected: rejections => {
            rejections.map(rejection => {
              rejection.errors.map(error => {
                if (error.code === 'file-too-large'){
                  notify('centaurus.file_too_large', {type: 'error'});
                  return;
                }
                notify(error.message, {type: 'error'});
              });
            });
          }
        }}
      >
        <ImageField source="src" />
      </ImageInput>
    </SimpleForm>
  </Create>
}