import styled from "@emotion/styled";
import { ShowButton, EditButton, DeleteWithConfirmButton } from 'react-admin';
import React from "react";
import MultilineText from "../app/MultilineText";
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

const Container = styled.div`
  border: 1px solid #c69f73;
  padding: .5rem;
  border-radius: .25rem;
  margin-bottom: 1rem;
  background-color: white;
  @media(min-width: 1024px){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0;
    flex-basis: calc(50% - .5rem);
    flex-grow: 0;
  }
  @media(min-width: 1200px){
    flex-basis: calc(33% - .5rem);
  }
  @media(min-width: 1600px){
    flex-basis: calc(25% - .75rem);
  }
`
const Cropper = styled.div`
  display: flex;
  height: 14rem;
  border-radius: .25rem .25rem 0 0;
  overflow: hidden;
  background-color: #F3EEE5;
  align-items: center;
  justify-content: center;
`
const Picture = styled.img`
  display: block;
  width: auto;
  height: auto;
  max-width: 110%;
`
const ProjectName = styled.p`
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #c69f73;
  color: #fff;
  margin: 0;
  padding: 1em .5em;
  border-radius: 0 0 .25rem .25rem;
  @media(min-width: 1024px){
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const ProjectNameCrop = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
const ProjectDescription = styled.p`
  margin: 1em 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const ProjectActions = styled.p`
  margin: 1em 0 0;
  display: flex;
`

export default function ProjectListItem(props){
  return <Container>
    <Box as="div" sx={{ "& a ": { textDecoration: 'none' },  "& a:hover": { textDecoration: 'underline', color: 'white'}}}>
      <Link to={props.showPath}>
        <Cropper>
          <Picture src={props.project.imageUrl} alt="image du projet" />
        </Cropper>
      </Link>
      <Link to={props.showPath}>
        <ProjectName>
          <ProjectNameCrop>
            {props.project.name}
          </ProjectNameCrop>
        </ProjectName>
      </Link>
      <ProjectDescription>
        <MultilineText text={props.project.description} />
      </ProjectDescription>
    </Box>
    <ProjectActions>
      {props.showPath &&
        <ShowButton label="Détails" to={props.showPath} record={props.project} sx={{ paddingLeft: 'revert'}}/>
      }
      {props.editPath &&
        <EditButton label="Editer" to={props.editPath} record={props.project} />
      }
      {props.deleteError && <p>ERROR !</p>}
      {props.onDelete &&
        <DeleteWithConfirmButton
          label="Supprimer"
          to={props.onDelete}
          record={props.project}
          disabled={props.isDeleting}
          sx={{marginLeft: 'auto'}}
          confirmTitle="Suppression d'un projet"
          confirmContent="Ce projet va être supprimé"
        />
      }
    </ProjectActions>
  </Container>
}
