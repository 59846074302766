import {Create, SimpleForm, TextInput} from "react-admin";

export default function UserCreate(){
  return <Create redirect="list">
    <SimpleForm>
      <TextInput source="firstname" label="Prénom" />
      <TextInput source="lastname" label="Nom" />
      <TextInput source="email" label="Email" />
    </SimpleForm>
  </Create>
}