import { List, useCreatePath, useDelete, useListContext, usePermissions } from "react-admin";
import PresentationalProjectListItem from '../presentational/ProjectListItem';
import PresentationalProjectList from '../presentational/ProjectList';

function RawProjectList(){
  const {data} = useListContext();
  return <PresentationalProjectList>
    {data.map(project => <ProjectListItem key={project.id} project={project} />)}
  </PresentationalProjectList>
}

function ProjectListItem(props){
  const createPath = useCreatePath();
  const [deleteProject, { isLoading, error }] = useDelete();
  const {permissions} = usePermissions();
  const onDelete = () => {
        deleteProject(
          'projects',
          { id: props.project.id, previousData: props.project }
        );
    }
  return <PresentationalProjectListItem
    project={props.project}
    onDelete={permissions === 'admin' && onDelete}
    isDeleting={isLoading}
    deleteError={error}
    showPath={createPath({resource: 'projects', type: 'show', id: props.project.id})}
    editPath={permissions === 'admin' && createPath({resource: 'projects', type: 'edit', id: props.project.id})}
  />
}

export default function ProjectList(props){
  const {permissions} = usePermissions();
  return <List
    {...props}
    emptyWhileLoading
    hasCreate={permissions === 'admin'}
    hasEdit={permissions === 'admin'}
    hasDelete={permissions === 'admin'}
    exporter={false}
    pagination={false}
    sx={{ height: "100%", backgroundColor: permissions === 'admin' ? 'white' : '#f3eee5', paddingRight: '1rem'}}
  >
    <RawProjectList />
  </List>
}