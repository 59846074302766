import React from 'react';
import { useRecordContext } from 'react-admin';

const CentaurusProjectImage = () => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <img src={record.imageUrl} title="First image" style={{ objectFit: "cover", height: "100%", width: "100%" }} />
    );
};

export default CentaurusProjectImage;